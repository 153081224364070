<template>
	<div v-if="!appLoading">
		<v-container>
			<v-row>
				<v-col>
					<a href="https://rewind-remind.me" target="_blank" class="d-flex align-center">
						<v-img alt="Rewind Logo" class="shrink mr-2" contain src="../../assets/logo.png" transition="scale-transition" width="150"/>
					</a>
				</v-col>
			</v-row>
			<v-row v-if="memory.id">
				<v-col cols="12" class="mx-auto">
					<v-row>
						<v-col cols="12" lg="8" class="mx-auto">
							<h3 class="text-h4 text-center">{{ memory.title }}</h3>
						</v-col>
						<v-col cols="12" lg="8" class="mx-auto">
							<p class="text-center">{{ prettyDate }}</p>
						</v-col>
						<v-col cols="12" class="mx-auto" v-if="memory.topics.length > 0">
							<v-chip-group column class="justify-center">
								<v-chip v-for="topic in memory.topics" outlined color="accent">
									{{ topic.name }}
								</v-chip>
							</v-chip-group>
						</v-col>
						<v-col cols="12" lg="8" class="mx-auto">
							<div style="white-space: pre-wrap;">{{ memory.description }}</div>
						</v-col>
					</v-row>
					<v-row class="mt-5">
						<v-col cols="12" sm="6" md="6" lg="4" v-if="memory.featured_image.url" @click="showLightbox(memory.featured_image)">
							<r-card-image :url="memory.featured_image.url" :thumb_url="memory.featured_image.thumb_url" show/>
						</v-col>
						<v-col v-for="attachment in memory.attachments_attributes" @click="showLightbox(attachment)" v-if="attachment.type === 'image'" cols="12" sm="6" md="6" lg="4">
							<r-card-image :url="attachment.url" :thumb_url="attachment.thumb_url" show/>
						</v-col>
						<v-col v-for="attachment in memory.attachments_attributes" v-if="attachment.type === 'video'" cols="12" sm="6" md="6" lg="4">
							<r-card-video :url="attachment.url"/>
						</v-col>
						<v-col v-for="attachment in memory.attachments_attributes" v-if="attachment.type === 'audio'" cols="12" sm="6" md="6" lg="4">
							<r-card-audio :url="attachment.url"/>
						</v-col>
						<v-col v-for="attachment in memory.attachments_attributes" v-if="attachment.type === 'doc'" cols="12" sm="6" md="6" lg="4">
							<r-card-doc :url="attachment.url" :filename="attachment.meta.filename"/>
						</v-col>
						<v-col v-for="url in memory.urls" cols="12" sm="6" md="6" lg="4">
							<r-card-url :url="url"/>
						</v-col>
						<v-col v-for="attachment in memory.attachments_attributes" v-if="attachment.type === 'unknown'" cols="12" sm="6" md="6" lg="4">
							<r-card-unknown :url="attachment.url"/>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-overlay color="background" opacity="0.9" :value="lightbox.show">
				<v-btn small style="position: fixed; bottom:0; right:0" fab color="accent" class="ma-5" @click="lightbox.show = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-btn small style="position: fixed; bottom:0; left: 20px;" fab color="accent" class="my-5" :href="lightbox.attachment.url" target="_blank">
					<v-icon>mdi-download</v-icon>
				</v-btn>
				<div class="lightbox">
					<div class="lightbox--inner" :style="`background-image: url(${lightbox.attachment.url})`">

					</div>
				</div>
			</v-overlay>
		</v-container>
	</div>
</template>

<script>
import RShareDialog from "@/views/memory/components/ShareDialog";
import Models from "@/models";

export default {
	name: "PublicShow",
	metaInfo() {
		return {
			title: this.memory?.title || 'Memory',
			meta: [
				{
					name: 'og:title',
					content: this.memory?.title || 'Memory'
				},
				{
					name: 'og:description',
					content: this.memory?.description || 'Memory'
				},
				{
					name: 'og:image',
					content: this.memory?.featured_image?.url || 'https://app2.rewind-remind.me/share.jpg'
				},
				{
					name: 'og:url',
					content: `https://app2.rewind-remind.me/${this.$route.fullPath}`
				}
			]
		}
	},
	props: ['token'],
	components: {
		RShareDialog
	},
	beforeMount() {
		this.getMemory()
	},
	computed: {
		prettyDate() {
			return this.memory.date ? new Date(this.memory.date).toLocaleDateString('en-GB') : new Date().toLocaleDateString('en-GB')
		}
	},
	data: () => ({
		memory: Models.Memory(),
		imageIndex: null,
		forgetDialog: false,
		lightbox: {
			show: false,
			attachment: {
				id: null,
				url: ''
			}
		}
	}),
	methods: {
		showLightbox(attachment) {
			this.lightbox.show = true;
			this.lightbox.attachment = attachment;
		},
		downloadAttachment() {
			this.$api.downloads.attachment(this.lightbox.attachment.id)
				.then(response => console.log(response))
				.catch(error => this.$toast.add('Sorry there was a problem', 'error'))
		},
		getMemory() {
			this.appLoading = true
			this.$api.memories.public_show(this.token)
				.then(response => this.memory = response.data)
				.catch(error => {
					console.log(error)
					this.$toast.add('Sorry there was a problem', 'error')
				})
				.finally(() => this.appLoading = false)
		},
	}
}
</script>


<style scoped lang="scss">
.download-btn {
	position: fixed;
	bottom: 0;
	left: 0;
	transform: translateX(calc(50vw - 60px))
}

.lightbox {
	height: 100vh;
	width: 100vw;

	.lightbox--inner {

		/* The image used */


		/* Full height */
		height: 100%;

		/* Center and scale the image nicely */
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}
}
</style>